// navigation
(function() {
    let isActive = false;

    document.querySelector("nav .trigger").addEventListener("click", ev => {
        ev.preventDefault();

        isActive = !isActive;

        if(isActive) {
            document.body.classList.add("nav-toggled");
        } else {
            document.body.classList.remove("nav-toggled");
        }
    });
})();

(function() {
    const main = document.querySelector("main");
    const branding = document.querySelector("body > header");

    const observer = new IntersectionObserver((entries) => {
            if(entries[0].isIntersecting) {
                document.body.classList.add("nav-trigger-inverse");
            } else {
                document.body.classList.remove("nav-trigger-inverse");
            }
        },
        {
            rootMargin: `0px 0px -${(branding.offsetHeight - 10)}px -${main.offsetWidth / 2}px`
        });

    observer.observe(main);
})();






(function(gallerySelector) {
    const parseThumbnailElements = images => {
        return Array.prototype.map.call(images.querySelectorAll("a"), link => {
            const thumbnail = link.querySelector("img");

            const item = {
                thumbnail: thumbnail,
                src: link.href,
                msrc: thumbnail.src,
                w: parseInt(link.dataset.width, 10),
                h: parseInt(link.dataset.height, 10)
            };

            return item;
        });
    };

    const openPhotoSwipe = (galleryElement, index) => {
        const items = parseThumbnailElements(galleryElement);
        const options = {
            index,
            history: false,
            getThumbBoundsFn: function(index) {
                const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
                const rect = items[index].thumbnail.getBoundingClientRect(); 

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            }

        };

        const pswpElement = document.querySelector(".pswp");
        const gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);

        gallery.init();
    };

    document.querySelectorAll( ".images" ).forEach(images => {
        images.querySelectorAll("a").forEach((link, index) => {
            link.addEventListener("click", e => {
                e.preventDefault();
                openPhotoSwipe( images, index )
            });
        });
    });
})();